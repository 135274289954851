import { useEffect, useState } from 'react';
import api from '../../services/api'
import Loading from '../Shared/Loading'
import { useFullUrl } from '../../utils/basePlaycentreUrl';

import styles  from './Roster.module.scss';

export default function Roster() {
  const baseUrl = useFullUrl();


  const [loading, setLoading] = useState(true);

  let [roster, setRoster] = useState([]);

  useEffect(() => {
    const loadMembers = () => {
      api
        .get(`${baseUrl}/roster`)
        .then((response) => {
          setRoster(response.data);          
          setLoading(false);          
        });
    };
    loadMembers();    

  }, [baseUrl]);


  if (loading)
    return <Loading />;


  const MemberCombinedNameInCell = ({ member }) => {
    const memberDisplayName = member && (`${member.displayName} ${member.rosterEducationName}${(member.firstAid ? 'FA' : '')}`);
    const memberBuddy = member && member.buddyName && <div className="text-end">({member.buddyName})</div>;  

    return (
      <>
        {!member?.helper &&
          <td className={member && `${styles[member.educationName]} ${member.hasUnderOneYO ? styles.hasUnderOneYO : ''} ${member.isSixMonthsPregnant ? styles.isSixMonthPregnant : ''} ${member.isFirstTerm ? styles.isFirstTerm : ''}`}>
            {memberDisplayName}
            {memberBuddy}
          </td>
        }
        {member?.helper &&
          <td>{member && `${member.helper.displayName}`}</td>
        }
      </>
    )
  };

  return (
    <>     
      <table className={`table table-bordered  ${styles.rosterTable}`}>
        <thead>
          <tr>
            <th colSpan={6} className="text-center">
              <h3>{roster.termName}</h3>
              <h5>{roster.playcentre?.displayName}</h5>
              <h4>{roster.termDates}</h4>
            </th>
          </tr>
          <tr>
            <th></th>
            {roster.dayNames.map((day, index) => (
              <th key={index}>{day}</th>
            ))} 
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>SESSION LEADER</th>
            {roster.sessionLeaders.map((member, index) => (
              <th key={index}>{member?.displayName}</th>
            ))}
          </tr>          
          {roster.ptLines.map((line, lineIndex) => (
            <tr key={lineIndex}>
              {lineIndex === 0 &&
                <th rowSpan={roster.ptLines.length}>
                  PLANNING TEAM
                  <br />
                  ARRIVE BEFORE 9.00AM
                </th>
              }
              {line.membersDays.map((member, memberIndex) => (                
                <MemberCombinedNameInCell key={memberIndex} member={member} />
              ))}
            </tr>
          ))}
          <tr className={styles.empty}><td colSpan={6}></td></tr>
          <tr className={styles.empty}><td colSpan={6}></td></tr>

          {roster.lines.map((line, lineIndex) => (
            <tr key={lineIndex}>
              {lineIndex === 0 &&
                <th rowSpan={roster.lines.length}>
                  ATTENDING MEMBERS
                  <br />
                  ARRIVE FROM 9.15AM
                </th>
              }
              {line.membersDays.map((member, memberIndex) => (
                <MemberCombinedNameInCell key={memberIndex} member={member} />                
              ))}
            </tr>
          ))}
          
          <tr className={styles.empty}><td colSpan={6}></td></tr>
          
          <tr className={styles.dropOff}>
            <th>DROP OFF PARENTS</th>
            {roster.dropOffParents.map((parentDay, index) => (
              <td key={index}>
                {parentDay.map((parent) =>(
                  <div key={parent.uid}>{parent.displayName} - {parent.phone}</div>
                ))}
              </td>
            ))}
          </tr>
          <tr className={styles.dropOff}>
            <th>DROP OFF KIDS</th>
            {roster.dropOffKidsCount.map((parent, index) => (
              <td key={index}>{parent}</td>
            ))}
          </tr>

          <tr className={styles.pregnantLine}>
            <th>MATERNITY LEAVE</th>
            {roster.maternityLeave.map((parent, index) => (
              <td key={index}>{parent}</td>
            ))}
          </tr>
          <tr>
            <th>CHILDREN ENROLLED</th>
            {roster.childrenEnrolledCount.map((enrolled, index) => (
              <td key={index}>{enrolled}</td>
            ))}
          </tr>

          <tr>
            <th rowSpan="2">CODES:</th>
            <td className={styles.TR}>TRAINING</td>
            <td className={styles.PIA}>PIA</td>
            <td className={styles.PEA}>PEA</td>
            <td className={styles.C3}>C3</td>
            <td className={styles.L4}>L4 - L8</td>
          </tr>
          <tr>            
            <td className={styles.hasUnderOneYO}>Has an under 1year old.</td>
            <td className={styles.isSixMonthPregnant}>6+ Months Pregnant</td>
            <td className={styles.isFirstTerm}>New member</td>
            <td></td>
            <td></td>
          </tr>

        </tbody>
      </table>
    </>
  );
}

